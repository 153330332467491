import React from 'react'
import {
  List, Icon, Button,
} from 'semantic-ui-react'

const SUPPORT_EMAIL = 'basesupport@7mindsets.com'

const getWhatsNewMessages = (t, translationPrefix) => {
  const whatsNewMessages = [
    {
      id: '0',
      header: t(`${translationPrefix}.messages.0.header`),
      summary: t(`${translationPrefix}.messages.0.summary`),
      date: new Date('7/15/2018'),
      content: (
        <div className='whatsNewModal'>
          <p><strong>{t(`${translationPrefix}.messages.0.content.sentence_1`)}</strong> {t(`${translationPrefix}.messages.0.content.sentence_2`)}</p>
          <List as='ul'>
            <List.Item as='li'>{t(`${translationPrefix}.messages.0.content.list_item_1`)}</List.Item>
            <List.Item as='li'>{t(`${translationPrefix}.messages.0.content.list_item_2`)}</List.Item>
            <List.Item as='li'>{t(`${translationPrefix}.messages.0.content.list_item_3`)}</List.Item>
          </List>
          <br/>
          <p className='center-align'><img width='90%' src='https://media.base.education/screenshots/enrollments.png' alt=''/></p>
        </div>
      ),
    },
    {
      id: '1',
      header: t(`${translationPrefix}.messages.1.header`),
      summary: t(`${translationPrefix}.messages.1.summary`),
      date: new Date('8/28/2018'),
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.1.content.sentence_1`)} <a href="mailto:basesupport@7mindsets.com">{SUPPORT_EMAIL}</a>. {t(`${translationPrefix}.messages.1.content.sentence_2`)}</p>
          <br/>
          <p className='center-align'><img width='90%' src='https://media.base.education/screenshots/instructional.png' alt=''/></p>
        </div>
      ),
    },
    {
      id: '2',
      header: t(`${translationPrefix}.messages.2.header`),
      summary: t(`${translationPrefix}.messages.2.summary`),
      date: new Date('10/22/2018'),
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.2.content.sentence_1`)}</p>
          <p className='center-align'><img width='90%' src='https://media.base.education/screenshots/active_users_new.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.2.content.sentence_2`)}</p>
          <p className='center-align'><img width='90%' src='https://media.base.education/screenshots/course_activity_new.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.2.content.sentence_3`)}</p>
          <p>{t(`${translationPrefix}.messages.2.content.sentence_4`)}</p>
          <p className='center-align'><img width='35%' src='https://media.base.education/screenshots/total_time.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.2.content.sentence_5`)}</p>
          <p className='center-align'><img width='90%' src='https://media.base.education/screenshots/firewords.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.2.content.sentence_6`)}</p>
          <p className='center-align'><img width='90%' src='https://media.base.education/screenshots/batch_users.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.2.content.sentence_7`)} <a href="mailto:basesupport@7mindsets.com">{SUPPORT_EMAIL}</a>.</p>
        </div>
      ),
    },
    {
      id: '3',
      header: t(`${translationPrefix}.messages.3.header`),
      summary: t(`${translationPrefix}.messages.3.summary`),
      date: new Date('10/28/2018'),
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.3.content.sentence_1`)}</p>
          <p>{t(`${translationPrefix}.messages.3.content.sentence_2`)}</p>
          <p className='center-align'><img width='90%' src='https://media.base.education/screenshots/discontinued.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.3.content.sentence_3`)}</p>
          <p>{t(`${translationPrefix}.messages.3.content.sentence_4`)}</p>
        </div>
      ),
    },
    {
      id: '4',
      header: t(`${translationPrefix}.messages.4.header`),
      summary: t(`${translationPrefix}.messages.4.summary`),
      date: new Date('11/13/2018'),
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.4.content.sentence_1`)}</p>
          <p>{t(`${translationPrefix}.messages.4.content.sentence_2`)}</p>
          <p>{t(`${translationPrefix}.messages.4.content.sentence_3`)}</p>
          <p className='center-align'><img width='90%' src='https://media.base.education/screenshots/filter_sort.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.4.content.sentence_4`)}</p>
          <p className='center-align'><img width='90%' src='https://media.base.education/screenshots/download_data.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.4.content.sentence_5`)}</p>
        </div>
      ),
    },
    {
      id: '5',
      header: t(`${translationPrefix}.messages.5.header`),
      summary: t(`${translationPrefix}.messages.5.summary`),
      date: new Date('02/14/2019'),
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.5.content.sentence_1`)}</p>
          <p className='center-align'><img width='90%' src='https://media.base.education/screenshots/access_schedule1.png' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.5.content.sentence_2`)}</p>
          <p className='center-align'><img width='90%' src='https://media.base.education/screenshots/access_schedule2.png' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.5.content.sentence_3`)}</p>
          <p className='center-align'><img width='90%' src='https://media.base.education/screenshots/access_schedule3.png' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.5.content.sentence_4`)}</p>
        </div>
      ),
    },
    {
      id: '6',
      header: t(`${translationPrefix}.messages.6.header`),
      summary: t(`${translationPrefix}.messages.6.summary`),
      date: new Date('03/04/2019'),
      content: (
        <div className='whatsNewModal'>
          <p className='center-align'><img width='90%' src='https://course.base.education/img/ecigarettes.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.6.content.sentence_1`)}</p>
          <p>{t(`${translationPrefix}.messages.6.content.sentence_2`)}</p>
          <p>{t(`${translationPrefix}.messages.6.content.sentence_3`)}</p>
          <p>{t(`${translationPrefix}.messages.6.content.sentence_4`)}</p>
        </div>
      ),
    },
    {
      id: '7',
      header: t(`${translationPrefix}.messages.7.header`),
      summary: t(`${translationPrefix}.messages.7.summary`),
      date: new Date('05/10/2019'),
      content: (
        <div className='whatsNewModal'>
          <p className='center-align'><img width='50%' src='https://course.base.education/img/dictionary-open.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.7.content.sentence_1`)}</p>
          <p>{t(`${translationPrefix}.messages.7.content.sentence_2`)}</p>
        </div>
      ),
    },
    {
      id: '8',
      header: t(`${translationPrefix}.messages.8.header`),
      summary: t(`${translationPrefix}.messages.8.summary`),
      date: new Date('08/05/2019'),
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.8.content.sentence_1`)}</p>
          <p>{t(`${translationPrefix}.messages.8.content.sentence_2`)}</p>
          <p className='center-align'><img width='90%' src='https://media.base.education/screenshots/admin_ui_1.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.8.content.sentence_3`)}</p>
          <p className='center-align'><img width='90%' src='https://media.base.education/screenshots/admin_ui_2.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.8.content.sentence_4`)}</p>
          <p>{t(`${translationPrefix}.messages.8.content.sentence_5`)}</p>
        </div>
      ),
    },
    {
      id: '9',
      header: t(`${translationPrefix}.messages.9.header`),
      summary: t(`${translationPrefix}.messages.9.summary`),
      date: new Date('10/11/2019'),
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.9.content.sentence_1`)}</p>
          <p className='center-align'><img width='50%' src='https://media.base.education/screenshots/languages.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.9.content.sentence_2`)}</p>
        </div>
      ),
    },
    {
      id: '10',
      header: t(`${translationPrefix}.messages.10.header`),
      summary: t(`${translationPrefix}.messages.10.summary`),
      date: new Date('11/3/2019'),
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.10.content.sentence_1`)}</p>
          <p className='center-align'><img width='70%' src='https://media.base.education/screenshots/review_fw_2.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.10.content.sentence_2`)}</p>
          <p className='center-align'><img width='70%' src='https://media.base.education/screenshots/review_fw_1.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.10.content.sentence_3`)}</p>
        </div>
      ),
    },
    {
      id: '11',
      header: t(`${translationPrefix}.messages.11.header`),
      summary: t(`${translationPrefix}.messages.11.summary`),
      date: new Date('03/24/2020'),
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.11.content.sentence_1`)}</p>
          <p className='center-align'><img width='70%' src='https://media.base.education/screenshots/new_languages2.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.11.content.sentence_2`)}</p>
        </div>
      ),
    },
    {
      id: '12',
      header: t(`${translationPrefix}.messages.12.header`),
      summary: t(`${translationPrefix}.messages.12.summary`),
      date: new Date('04/08/2020'),
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.12.content.sentence_1`)}</p>
          <p className='center-align'><img width='70%' src='https://media.base.education/screenshots/admin-new-nav-link.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.12.content.sentence_2`)}</p>
          <p className='center-align'><img width='70%' src='https://media.base.education/screenshots/admin-new-nav.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.12.content.sentence_3`)}</p>
        </div>
      ),
    },
    {
      id: '13',
      header: t(`${translationPrefix}.messages.13.header`),
      summary: t(`${translationPrefix}.messages.13.summary`),
      date: new Date('04/23/2020'),
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.13.content.sentence_1`)}</p>
          <p className='center-align'><img width='70%' src='https://media.base.education/screenshots/stress_management.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.13.content.sentence_2`)}</p>
          <p>{t(`${translationPrefix}.messages.13.content.sentence_3`)}</p>
        </div>
      ),
    },
    {
      id: '14',
      header: t(`${translationPrefix}.messages.14.header`),
      summary: t(`${translationPrefix}.messages.14.summary`),
      date: new Date('07/22/2020'),
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.14.content.sentence_1`)}</p>
          <p className='center-align'><img width='70%' src='https://media.base.education/screenshots/admin-new-nav-2.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.14.content.sentence_2`)}</p>
          <p>{t(`${translationPrefix}.messages.14.content.sentence_3`)}</p>
        </div>
      ),
    },
    {
      id: '15',
      header: t(`${translationPrefix}.messages.15.header`),
      summary: t(`${translationPrefix}.messages.15.summary`),
      date: new Date('08/27/2020'),
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.15.content.sentence_1`)}</p>
          <p>{t(`${translationPrefix}.messages.15.content.sentence_2`)}</p>
          <p className='center-align'><img width='70%' src='https://media.base.education/screenshots/login.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.15.content.sentence_3`)}</p>
        </div>
      ),
    },
    // {
    //   id: '16',
    //   header: t(`${translationPrefix}.messages.16.header`),
    //   summary: t(`${translationPrefix}.messages.16.summary`),
    //   date: new Date('10/13/2020'),
    //   content: (
    //     <div className='whatsNewModal'>
    //       <p>{t(`${translationPrefix}.messages.16.content.sentence_1`)}</p>
    //       <p className='center-align text bold'>{t(`${translationPrefix}.messages.16.content.sentence_2`)}</p>
    //       <p className='center-align'><img width='70%' src='https://media.base.education/screenshots/elementary.jpg' alt=''/></p>
    //       <p>{t(`${translationPrefix}.messages.16.content.sentence_3`)}</p>
    //     </div>
    //   ),
    // },
    {
      id: '17',
      header: t(`${translationPrefix}.messages.17.header`),
      summary: t(`${translationPrefix}.messages.17.summary`),
      date: new Date('11/12/2020'),
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.17.content.sentence_1`)}</p>
          <p className='center-align'><img width='30%' src='https://media.base.education/screenshots/equity-course.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.17.content.sentence_2`)}</p>
          <p>{t(`${translationPrefix}.messages.17.content.sentence_3`)}</p>
        </div>
      ),
    },
    {
      id: '18',
      header: t(`${translationPrefix}.messages.18.header`),
      summary: t(`${translationPrefix}.messages.18.summary`),
      date: new Date('11/30/2020'),
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.18.content.sentence_1`)}</p>
          <p style={{ textAlign: 'center' }}><strong>{t(`${translationPrefix}.messages.18.content.sentence_2`)}</strong></p>
          <p>{t(`${translationPrefix}.messages.18.content.sentence_3`)}</p>
          <p>{t(`${translationPrefix}.messages.18.content.sentence_4`)}</p>
          <List as='ul'>
            <List.Item as='li'>{t(`${translationPrefix}.messages.18.content.list_item_1`)}</List.Item>
            <List.Item as='li'>{t(`${translationPrefix}.messages.18.content.list_item_2`)}</List.Item>
            <List.Item as='li'>{t(`${translationPrefix}.messages.18.content.list_item_3`)}</List.Item>
            <List.Item as='li'>{t(`${translationPrefix}.messages.18.content.list_item_4`)}</List.Item>
            <List.Item as='li'>{t(`${translationPrefix}.messages.18.content.list_item_5`)}</List.Item>
            <List.Item as='li'>{t(`${translationPrefix}.messages.18.content.list_item_6`)}</List.Item>
            <List.Item as='li'>{t(`${translationPrefix}.messages.18.content.list_item_7`)}</List.Item>
          </List>
          <br/>
          <p><strong>{t(`${translationPrefix}.messages.18.content.sentence_5`)}</strong></p>
          <p>{t(`${translationPrefix}.messages.18.content.sentence_6`)}</p>
          <p className='center-align'><img width='80%' src='https://media.base.education/screenshots/courses_tab.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.18.content.sentence_7`)}</p>
          <p className='center-align'><img width='25%' src='https://media.base.education/screenshots/educator_guides_hs.jpg' alt=''/></p>
        </div>
      ),
    },
    {
      id: '19',
      header: t(`${translationPrefix}.messages.19.header`),
      summary: t(`${translationPrefix}.messages.19.summary`),
      date: new Date('08/29/2021'),
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.19.content.sentence_1`)}</p>
          <p className='center-align'><img width='25%' src='https://media.base.education/screenshots/mindfulness.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.19.content.sentence_2`)}</p>
        </div>
      ),
    },
    {
      id: '20',
      header: t(`${translationPrefix}.messages.20.header`),
      summary: t(`${translationPrefix}.messages.20.summary`),
      date: new Date('09/21/2021'),
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.20.content.sentence_1`)}</p>
          <p className='center-align'><img width='25%' src='https://media.base.education/screenshots/new-normal-course.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.20.content.sentence_2`)}</p>
          <p>{t(`${translationPrefix}.messages.20.content.sentence_3`)}</p>
          <p>{t(`${translationPrefix}.messages.20.content.sentence_4`)}</p>
          <p className='center-align'><i className='fas fa-file-pdf base-teal' /> <a className='text base-teal' rel='noopener noreferrer' href='https://media.base.education/documents/courses/base-education-navigating-adjusting-to-todays-new-normal.pdf' target='_blank'>Click here to view the answer reference</a></p>
        </div>
      ),
    },
    {
      id: '21',
      header: t(`${translationPrefix}.messages.21.header`),
      summary: t(`${translationPrefix}.messages.21.summary`),
      date: new Date('10/13/2021'),
      forOrgId: null, // shows for all orgs
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.21.content.sentence_1`)}</p>
        </div>
      ),
    },
    {
      id: '22',
      header: t(`${translationPrefix}.messages.22.header`),
      summary: t(`${translationPrefix}.messages.22.summary`),
      date: new Date('01/20/2022'),
      forOrgId: 'o_2435cc57_1215_455f_ad84_b5be86d3593a', // BASE customers
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.22.content.sentence_1`)}</p>
          <p className='center-align'><Icon name='external' className='base-teal' /> <a className='text base-teal' rel='noopener noreferrer' href="https://sites.google.com/base.education/baseexceptionalservices/home" target='_blank'>Click here to read more</a></p>
        </div>
      ),
    },
    {
      id: '23',
      header: t(`${translationPrefix}.messages.23.header`),
      summary: t(`${translationPrefix}.messages.23.summary`),
      date: new Date('03/04/2022'),
      forOrgId: null,
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.23.content.sentence_1`)}</p>
          <p className='center-align'><img width='50%' src='https://media.base.education/screenshots/elementary-video-subtitles.jpg' alt=''/></p>
          <p>{t(`${translationPrefix}.messages.23.content.sentence_2`)}</p>
          <p className='center-align'><Icon name='external' className='base-teal' /> <a className='text base-teal' rel='noopener noreferrer' href="https://media.base.education/videos/elementary-video-subtitles.mp4" target='_blank'>How to access Elementary Video Captions</a></p>
        </div>
      ),
    },
    {
      id: '24',
      header: t(`${translationPrefix}.messages.24.header`),
      summary: t(`${translationPrefix}.messages.24.summary`),
      date: new Date('03/20/2022'),
      forOrgId: 'o_2435cc57_1215_455f_ad84_b5be86d3593a', // BASE customers
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.24.content.sentence_1`)}</p>
          <p className='center-align'><Icon name='external' className='base-teal' /> <a className='text base-teal' rel='noopener noreferrer' href="https://sites.google.com/base.education/basereflectionsnewsletter/home" target='_blank'>Click here to read more</a></p>
        </div>
      ),
    },
    {
      id: '25',
      header: t(`${translationPrefix}.messages.25.header`),
      summary: t(`${translationPrefix}.messages.25.summary`),
      date: new Date('05/19/2022'),
      forOrgId: 'o_2435cc57_1215_455f_ad84_b5be86d3593a', // BASE customers
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.25.content.sentence_1`)}</p>
          <p className='center-align'><Icon name='external' className='base-teal' /> <a className='text base-teal' rel='noopener noreferrer' href="https://sites.google.com/base.education/base-reflections-may-june/home" target='_blank'>Click here to read more</a></p>
        </div>
      ),
    },
    {
      id: '26',
      header: t(`${translationPrefix}.messages.26.header`),
      summary: t(`${translationPrefix}.messages.26.summary`),
      date: new Date('08/22/2022'),
      forOrgId: 'o_2435cc57_1215_455f_ad84_b5be86d3593a', // BASE customers
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.26.content.sentence_1`)}</p>
          <p className='center-align'><Icon name='external' className='base-teal' /> <a className='text base-teal' rel='noopener noreferrer' href="https://sites.google.com/base.education/base-reflections-newsletter/reflections-editions" target='_blank'>Click here to read more</a></p>
        </div>
      ),
    },
    {
      id: '28',
      header: t(`${translationPrefix}.messages.28.header`),
      summary: t(`${translationPrefix}.messages.28.summary`),
      date: new Date('09/21/2022'),
      forOrgId: null,
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.28.content.sentence_1`)}</p>
          <p>{t(`${translationPrefix}.messages.28.content.sentence_2`)}</p>
          <p>{t(`${translationPrefix}.messages.28.content.sentence_3`)}</p>
        </div>
      ),
    },
    {
      id: '29',
      header: t(`${translationPrefix}.messages.29.header`),
      summary: t(`${translationPrefix}.messages.29.summary`),
      date: new Date('10/05/2022'),
      forOrgId: 'o_2435cc57_1215_455f_ad84_b5be86d3593a', // BASE customers
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.29.content.sentence_1`)}</p>
          <p className='center-align'><Icon name='external' className='base-teal' /> <a className='text base-teal' rel='noopener noreferrer' href="https://sites.google.com/base.education/base-reflections-newsletter/home" target='_blank'>{t(`${translationPrefix}.messages.29.content.sentence_2`)}</a></p>
        </div>
      ),
    },
    {
      id: '30',
      header: t(`${translationPrefix}.messages.30.header`),
      summary: t(`${translationPrefix}.messages.30.summary`),
      date: new Date('12/15/2022'),
      forOrgId: 'o_2435cc57_1215_455f_ad84_b5be86d3593a', // BASE customers
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.30.content.sentence_1`)}</p>
          <p className='center-align'><Icon name='external' className='base-teal' /> <a className='text base-teal' rel='noopener noreferrer' href="https://sites.google.com/base.education/base-reflections-newsletter/reflections-editions/dec-2022-jan-2023-edition" target='_blank'>{t(`${translationPrefix}.messages.30.content.sentence_2`)}</a></p>
        </div>
      ),
    },
    {
      id: '31',
      header: t(`${translationPrefix}.messages.31.header`),
      summary: t(`${translationPrefix}.messages.31.summary`),
      date: new Date('2/16/2023'),
      forOrgId: 'o_2435cc57_1215_455f_ad84_b5be86d3593a', // BASE customers,
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.31.content.sentence_1`)}</p>
          <p className='center-align'><Icon name='external' className='base-teal' /> <a className='text base-teal' rel='noopener noreferrer' href="https://sites.google.com/base.education/base-reflections-newsletter/home" target='_blank'>{t(`${translationPrefix}.messages.31.content.sentence_2`)}</a></p>
        </div>
      ),
    },
    {
      id: '32',
      header: t(`${translationPrefix}.messages.32.header`),
      summary: t(`${translationPrefix}.messages.32.summary`),
      date: new Date('5/07/2023'),
      forOrgId: 'o_2435cc57_1215_455f_ad84_b5be86d3593a', // BASE customers,
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.32.content.sentence_1`)}</p>
          <p className='center-align'><Icon name='external' className='base-teal' /> <a className='text base-teal' rel='noopener noreferrer' href="https://sites.google.com/base.education/base-reflections-newsletter/home" target='_blank'>{t(`${translationPrefix}.messages.32.content.sentence_2`)}</a></p>
        </div>
      ),
    },
    {
      id: '33',
      header: t(`${translationPrefix}.messages.33.header`),
      summary: t(`${translationPrefix}.messages.33.summary`),
      date: new Date('5/08/2023'),
      forOrgId: null,
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.33.content.sentence_1`)}</p>
          <p>{t(`${translationPrefix}.messages.33.content.sentence_2`)}</p>
          <p className='center-align'><Icon name='external' className='base-teal' /> <a className='text base-teal' rel='noopener noreferrer' href="https://downloads.base.education/website/recruitment-letter.pdf" target='_blank'>{t(`${translationPrefix}.messages.33.content.sentence_3`)}</a></p>
        </div>
      ),
    },
    {
      id: '34',
      header: t(`${translationPrefix}.messages.34.header`),
      summary: t(`${translationPrefix}.messages.34.summary`),
      date: new Date('11/21/2023'),
      forOrgId: null,
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.34.content.sentence_1`)}</p>
          <p>{t(`${translationPrefix}.messages.34.content.sentence_2`)}</p>
          <p>{t(`${translationPrefix}.messages.34.content.sentence_3`)}</p>
          <p>{t(`${translationPrefix}.messages.34.content.sentence_4`)}</p>
        </div>
      ),
    },
    {
      id: '35',
      header: t(`${translationPrefix}.messages.35.header`),
      summary: t(`${translationPrefix}.messages.35.summary`),
      date: new Date('12/01/2023'),
      forOrgId: null,
      content: (
        <div className='whatsNewModal center-align'>
          <div style={{ backgroundColor: '#09467e', marginBottom: 10 }}>
            <img alt='mindsets university. invigorate. inspire. empower' src='https://7mindsets.com/wp-content/uploads/2023/02/7MU-Logo.png'></img>
          </div>
          <p>
            <strong>{t(`${translationPrefix}.messages.35.content.sentence_1`)}</strong>
            <br/>
            {t(`${translationPrefix}.messages.35.content.sentence_2`)}
          </p>
          <p>{t(`${translationPrefix}.messages.35.content.sentence_3`)}</p>
          <p><strong>{t(`${translationPrefix}.messages.35.content.sentence_4`)}</strong></p>
          <Button
            as='a'
            href='https://7mindsets.com/university/'
            className='base-green-bg text white'
            target='_blank'
          >
            {t(`${translationPrefix}.messages.35.content.sentence_5`)}
          </Button>
        </div>
      ),
    },
    {
      id: '36',
      header: t(`${translationPrefix}.messages.36.header`),
      summary: t(`${translationPrefix}.messages.36.summary`),
      date: new Date('12/20/2023'),
      forOrgId: null,
      content: (
        <div className='whatsNewModal'>
          <p>{t(`${translationPrefix}.messages.36.content.sentence_1`)}</p>
          <p>{t(`${translationPrefix}.messages.36.content.sentence_2`)}</p>
          <p>{t(`${translationPrefix}.messages.36.content.sentence_3`)}</p>
        </div>
      ),
    },
    {
      id: '37',
      header: t(`${translationPrefix}.messages.37.header`),
      summary: t(`${translationPrefix}.messages.37.summary`),
      date: new Date('03/01/2024'),
      forOrgId: null,
      content: (
        <div className='whatsNewModal'>
          <p style={{ textAlign: 'left' }}>{t(`${translationPrefix}.messages.37.content.sentence_1`)}</p>
          <a className='text base-teal' rel='noopener noreferrer' href="https://7mindsets.typeform.com/to/vF8L8cSn" target='_blank'><Icon name='list alternate outline' className='base-teal' /> {t(`${translationPrefix}.messages.37.content.sentence_2`)}</a>
        </div>
      ),
    },
    {
      id: '38',
      header: t(`${translationPrefix}.messages.38.header`),
      summary: t(`${translationPrefix}.messages.38.summary`),
      date: new Date('03/14/2024'),
      forOrgId: null,
      content: (
        <div className='whatsNewModal'>
          <p style={{ textAlign: 'left' }}>{t(`${translationPrefix}.messages.38.content.sentence_1`)}</p>
          <p style={{ textAlign: 'left' }}>{t(`${translationPrefix}.messages.38.content.sentence_2`)}</p>
          <p style={{ textAlign: 'left' }}>{t(`${translationPrefix}.messages.38.content.sentence_3`)}</p>
        </div>
      ),
    },
  ]
  return whatsNewMessages
}

export default getWhatsNewMessages
