import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Segment,
  Header,
  Checkbox,
  Icon,
  Accordion,
  List,
  Message,
} from 'semantic-ui-react'
import {
  intersection,
  difference,
  union,
  flatMap,
  map,
  sortBy,
  find,
} from 'lodash'

import './course-selector.css'

const coursePacks = {
  elementaryVideos: {
    name: 'Elementary Video Collection',
    description: 'These are elementary video modules.',
    courseIds: [
      'c_02159e04_82be_412c_88ac_ac42659b7417',
      'c_29dd03c6_717a_4d95_8089_d50de52f15d3',
      'c_2c4194ea_a940_48b2_a7a4_931304887c0c',
      'c_60773c63_0aae_44b8_9ce5_a4d916859f75',
      'c_6c38638a_4b89_411b_a506_785aa3998906',
      'c_719ec31f_ca87_4fa7_81ba_84ffbec98792',
      'c_9a5122e5_fb25_4e73_a305_f6dc8196e9c4',
      'c_9f8f9f4c_34a2_4247_8c8f_62231736b511',
      'c_c8db250b_75ad_4bbc_9606_ef49d3f1d114',
      'c_d2157b85_05eb_4553_9d6b_b402bab82ddd',
      'c_f286d888_4b5c_41bd_a67a_4c244ef467e5',
      'c_f8780ae9_9bf9_4c47_99a2_db7c9687bca7',
      'c_e18d5998_f6d8_4720_947d_57ebb37f2ae6',
      'c_475ca917_23f2_4447_87c0_008a1683f247',
      'c_782d7914_9187_4482_9d8f_0215c54f0a7a',
      'c_a696364b_68f0_411a_a230_89385d810ccb',
      'c_e3080741_80f8_4b9f_b4d8_29c2475d0f01',
      'c_04b64e32_4a87_424e_8107_16d450b9e8ba',
      'c_80557ee3_278b_40f8_8bfc_97065e248b0d',
      'c_2a7d2bb1_4d3d_4983_81be_474d936c64f0',
      'c_961c17ce_6455_4059_b8e2_710b95e16827',
      'c_6ec2c9c4_f33a_45d6_881e_81085f8d592f',
      'c_42711c97_a5cb_40e4_b0db_2cb2b2a24474',
      'c_8b356576_81cf_4328_a16f_e49a28982e8e',
      'c_49fdc019_bd90_406b_a220_0dfd6f7ad245',
      'c_f25f7861_612c_4ea3_b105_410e7e69c826',
      'c_a00a45fb_ab0d_48dd_ac63_063c192d5d28',
      'c_539edc14_c00b_434e_bf6d_9ed4e5e9ad22',
      'c_9ab482d8_07bc_4bb4_bfe3_b913e5efc727',
      'c_a65b3c37_6dbb_4dac_8a9c_337591639388',
    ],
  },
  elementary: {
    name: 'Elementary Module Collection',
    description: 'These are elementary modules.',
    courseIds: [
      '5b2131239915850019fbc9a6',
      '5b60f6643c27c40019491192',
      '5a71d903d10210000f9306a3',
      '5c9e6480ea16af729dd36b90',
    ],
  },
  high: {
    name: 'High School Module Collection',
    description: 'These are high school modules.',
    courseIds: [
      '62df06fac2b3300e6609910d',
      '62df0762cc67f44d15586a60',
      '62e0250bbd9fa95c3aafb397',
      '62e2f0ab57cfe66775a7604c',
      '62ed477fbf0aab4b29d13955',
      '62f18caa7e05aef30c8523f5',
      '62f18e337e05aef30c85260d',
      '62f1912fa1086ca903cc9d18',
      '62f19319a1086ca903cc9f4b',
      '62f198ae8ec9a17fb646b647',
      '62f19c34b2c8ed967d3b1b37',
      '62f19f2327dbd8a1d792faf0',
      '62f1a07527dbd8a1d792fc5b',
      '62f1a0d127dbd8a1d792fd85',
      '62f1a15527dbd8a1d792ff64',
      '62f1a1d627dbd8a1d793014b',
      '62f572ba46cc49ea8b3cfbc9',
      '62f5735b46cc49ea8b3cfda3',
      '62f573f846cc49ea8b3cffa2',
      '62f5757291397d323adca89e',
      '62f57b83e81e5d1bc26c8e85',
      '62f57cc8e81e5d1bc26c8f8a',
      '62f57d22e81e5d1bc26c9140',
      '62f580b5be75ff61155566f1',
      '62f580f5be75ff611555685e',
      '62f5825ebe75ff6115556973',
      '62f5828dbe75ff6115556a94',
      '62f582c5be75ff6115556bf7',
      '62f5831cbe75ff6115556d57',
      '62f5837fbe75ff6115556ef2',
      '62f5849bbe75ff6115557083',
      '62f58583be75ff6115557255',
      '62f58637be75ff6115557429',
      '62f58793be75ff6115557629',
      '62f58847be75ff611555789e',
      '62f588ecbe75ff6115557b12',
      '62f58936be75ff6115557ce9',
      '63584cb7bfe6838d7646f4bf',
      '63584cebbfe6838d7646f524',
      '63584d22bfe6838d7646f582',
      '63584d44bfe6838d7646f5f5',
      '63584d7dbfe6838d7646f72c',
      '63584dbbbfe6838d7646f897',
      '63584e07bfe6838d7646f957',
      '63584e4cbfe6838d7646f9f0',
      '63584e9abfe6838d7646fa69',
      '6359d5ecd83cf27448a84d6f',
      '6359f2436c48ebd867973ccc',
      '6359f2846c48ebd867973d57',
      '6359f2c06c48ebd867973dd8',
      '6359f30c6c48ebd867973e16',
      '6359f36a6c48ebd867973ecd',
      '6359f3a86c48ebd867973fc0',
      '6359f4036c48ebd867974047',
      '6359f4cf6c48ebd867974121',
      '6359f5006c48ebd86797419d',
      '6359f6786c48ebd867974268',
      '6359f6916c48ebd8679742a1',
      '6359f6aa6c48ebd8679742e2',
      '6359f6c76c48ebd86797431e',
      '6359f6e36c48ebd86797436e',
      '6359f7086c48ebd867974404',
      '6359f72e6c48ebd8679744d0',
      '6359f7556c48ebd867974572',
      '6359f7736c48ebd8679745db',
      '6359f7956c48ebd86797468f',
      '6359f7c86c48ebd86797474d',
      '6359f7f46c48ebd867974812',
      '635c1bef0cf99e6b06ac6aa7',
      '635c1c0b0cf99e6b06ac6b21',
      '635c1c1d0cf99e6b06ac6b66',
      '635c1c300cf99e6b06ac6ba5',
      '635c1c450cf99e6b06ac6be7',
      '635c1c5c0cf99e6b06ac6c5d',
      '635c1c760cf99e6b06ac6d55',
      '635c1c920cf99e6b06ac6e11',
      '64b8c5813c9b115f566d7f0e',
      '64b8c5a33c9b115f566d7f78',
      '64b8c5c33c9b115f566d7fe3',
      // '64b8c5ff3c9b115f566d8099', Anxiety Instructional - Not ready for release yet
      '64b8c6353c9b115f566d8176',
      '64b8c6623c9b115f566d8221',
      '64b8c7023c9b115f566d831e',
      '64b8c7253c9b115f566d8391',
      '64b8c75a3c9b115f566d83ff',
      '64b8c7ab3c9b115f566d8498',
      '64b8c7e63c9b115f566d85be',
      // '64b8c82e3c9b115f566d8726', Equity Instructional - Not ready for release yet
      '64b8c85f3c9b115f566d87a3',
      '64b8c89a3c9b115f566d886a',
      '64b8c8de3c9b115f566d88f9',
      '64b8c9173c9b115f566d89b0',
      '64b8c93e3c9b115f566d8a4b',
      '64b8c9653c9b115f566d8ad9',
      '64b8c9a13c9b115f566d8bec',
      '64b8c9ed3c9b115f566d8c83',
      '64b8ca113c9b115f566d8cf2',
      '64b8ca383c9b115f566d8d25',
      '64b8ca713c9b115f566d8dcc',
      '64b8ca8f3c9b115f566d8e2d',
      '64b8cadc3c9b115f566d8ec4',
      '64b8cb023c9b115f566d8f0c',
      // '64b8cb313c9b115f566d8f73', Resilience Instructional - Not ready for release yet
      '64b8cb6d3c9b115f566d8fd3',
      '64b8cb953c9b115f566d9060',
      '64b8cbc13c9b115f566d9128',
      '64b8cbf03c9b115f566d91f7',
      // '64b8cc1b3c9b115f566d928b', Drug Facts - Alcohol Instructional - Not ready for release yet
      // '64b8cc493c9b115f566d92c4', Drug Facts - Bath Salts Instructional - Not ready for release yet
      // '64b8cc573c9b115f566d92de', Drug Facts - Tobacco and Nicotine - Not ready for release yet
      // '64b8cc683c9b115f566d930a', Drug Facts - Cocaine Instructional - Not ready for release yet
      // '64b8cc943c9b115f566d9331', Drug Facts - Hallucinogens and Dissociative Drugs Instructional - Not ready for release yet
      // '64b8ccab3c9b115f566d9389', Drug Facts - Heroin, Fentanyl, and Synthetic Street Opioids Instructional - Not ready for release yet
      // '64b8ccbd3c9b115f566d93bc', Drug Facts - Inhalants and the Choking Game Instructional - Not ready for release yet
      // '64b8ccd23c9b115f566d93f1', Drug Facts - MDMA/Ecstasy Instructional - Not ready for release yet
      // '64b8cce73c9b115f566d9420', Drug Facts - Marijuana and Concentrates Instructional - Not ready for release yet
      // '64b8cd0e3c9b115f566d94b7', Drug Facts - Methamphetamine Instructional - Not ready for release yet
      // '64b8cd273c9b115f566d94df', Drugs and the Mind - Emotions and Drugs Instructional - Not ready for release yet
      '64b8cd363c9b115f566d94fa',
      '64b8cd7c3c9b115f566d961f',
      '64b8cdb63c9b115f566d971c',
      '64b8cdf13c9b115f566d97df',
      '64b16e1feceb6ff9b53b069b',
      '64b1a7cc9e67f4517569ea39',
      '64b1a8edeceb6ff9b53b06b3',
      '64b1a90b1e3a5dc17cce04dc',
      '64b1a938ee84e9d37850e7cc',
      '64b1ad1139cc7af1b759da11',
      '64b1a95839cc7af1b759da08',
      '64b1a971a42b0a941dfa18df',
      '656530caa0c3a83aa0fc9281',
      '65653113a0c3a83aa0fc935b',
      '6565311fa0c3a83aa0fc937d',
      '65653130a0c3a83aa0fc93d9',
      '6565314aa0c3a83aa0fc9433',
      '6565315ba0c3a83aa0fc9480',
      '6565316aa0c3a83aa0fc94c8',
      '6565317ba0c3a83aa0fc950e',
    ],
  },
  middle: {
    name: 'Middle School Module Collection',
    description: 'These are middle school modules.',
    courseIds: [
      '62f58919be75ff6115557c56',
      '63584d52bfe6838d7646f628',
      '62f585edbe75ff611555736e',
      '62f5835abe75ff6115556e1a',
      '62f582a9be75ff6115556b5a',
      '62f58096be75ff611555663e',
      '62f57cf8e81e5d1bc26c9043',
      '62f57b9be81e5d1bc26c8ed1',
      '6359d5c6d83cf27448a84cbb',
      '62f573a946cc49ea8b3cfeed',
      '62f5726746cc49ea8b3cfb39',
      '62f1a18f27dbd8a1d793008d',
      '62f1a11c27dbd8a1d792fe3b',
      '62f1a09c27dbd8a1d792fccf',
      '62f19d35b2c8ed967d3b1d00',
      '62f19b1db2c8ed967d3b196e',
      '62f190a3a1086ca903cc9c78',
      '62f18d427e05aef30c852551',
      '62f18b997e05aef30c852299',
      '62e2ef6f57cfe66775a75f3a',
      '62e024a8bd9fa95c3aafb2cb',
      '62df0746cc67f44d155869cf',
      '63584c99bfe6838d7646f478',
      '62df06ccc2b3300e6609906e',
      '62ed4465e81824d8b2088e7a',
      '62f192e8a1086ca903cc9e7c',
      '62f1936aa1086ca903cca01a',
      '62f1a02e27dbd8a1d792fbe7',
      '62f5730a46cc49ea8b3cfc59',
      '62f574a446cc49ea8b3d0057',
      '62f578ccc47251688d9a24f8',
      '62f580d2be75ff61155567a4',
      '62f5824cbe75ff6115556918',
      '62f58271be75ff61155569ce',
      '62f582e0be75ff6115556c94',
      '62f58438be75ff6115556fca',
      '62f5854abe75ff611555713c',
      '62f586debe75ff61155574e4',
      '62f58813be75ff611555776e',
      '62f5888ebe75ff61155579ce',
      '63584cd4bfe6838d7646f506',
      '63584d04bfe6838d7646f542',
      '63584d37bfe6838d7646f5c2',
      '63584da5bfe6838d7646f830',
      '63584de1bfe6838d7646f8fe',
      '63584e1abfe6838d7646f9b0',
      '63584e5fbfe6838d7646fa30',
      '6359f2226c48ebd867973c9c',
      '6359f2606c48ebd867973cfc',
      '6359f2a96c48ebd867973db2',
      '6359f2ef6c48ebd867973dfe',
      '6359f3216c48ebd867973e2e',
      '6359f38e6c48ebd867973f6c',
      '6359f3d46c48ebd867974014',
      '6359f4ba6c48ebd8679740ec',
      '6359f4ee6c48ebd867974156',
      '6359f6656c48ebd867974254',
      '6359f6856c48ebd86797427c',
      '6359f6a26c48ebd8679742c6',
      '6359f6b86c48ebd8679742fe',
      '6359f6d66c48ebd86797433e',
      '6359f6f36c48ebd86797439e',
      '6359f71d6c48ebd86797446a',
      '6359f7446c48ebd867974536',
      '6359f7666c48ebd8679745ae',
      '6359f77f6c48ebd867974608',
      '6359f7ba6c48ebd867974716',
      '6359f7d76c48ebd867974784',
      '635c1bdb0cf99e6b06ac6a57',
      '635c1c010cf99e6b06ac6af7',
      '635c1c170cf99e6b06ac6b4b',
      '635c1c290cf99e6b06ac6b81',
      '635c1c3f0cf99e6b06ac6bc9',
      '635c1c500cf99e6b06ac6c05',
      '635c1c6a0cf99e6b06ac6cb5',
      '635c1c880cf99e6b06ac6df5',
      '649b8f871a74c2dccaafc0bd',
      '648236f00eafebeb9ad4d798',
      '648237f30eafebeb9ad4d7f7',
      '648238390eafebeb9ad4d852',
      '648238530eafebeb9ad4d89a',
      '648238690eafebeb9ad4d8e3',
      '648238cb0eafebeb9ad4d939',
      '649b8fad1a74c2dccaafc12a',
      '648238e90eafebeb9ad4d98f',
      '6482398e0eafebeb9ad4d9e7',
      '648239e40eafebeb9ad4da3c',
      '648239ff0eafebeb9ad4da96',
      '64823a470eafebeb9ad4db15',
      '64823a670eafebeb9ad4db89',
      '64823a890eafebeb9ad4dc13',
      '64823ae80eafebeb9ad4dd3d',
      '64823bb90eafebeb9ad4dd83',
      '64823bd10eafebeb9ad4ddd6',
      '64823c750eafebeb9ad4de63',
      '64823c980eafebeb9ad4deea',
      '649b906e1a74c2dccaafc197',
      '649b908e1a74c2dccaafc1e6',
      '648255c9848fb5cc895eebcc',
      '64825736adeca4f16efc337f',
      '64825f52ac5ab232f4bf127f',
      '64825f6fac5ab232f4bf12e5',
      '64825fb6ac5ab232f4bf13c6',
      '64825fddac5ab232f4bf147d',
      '64825ff6ac5ab232f4bf14d0',
      '6482603bac5ab232f4bf1502',
      '64826053ac5ab232f4bf156b',
      '649b90f81a74c2dccaafc217',
      '6482609aac5ab232f4bf15f9',
      '648260b6ac5ab232f4bf1665',
      '648260d2ac5ab232f4bf16d5',
      '64826edd9a640ea82649d3b7',
      '64826f129a640ea82649d46e',
      '64826f309a640ea82649d4ea',
      '64826f449a640ea82649d536',
      '64826f539a640ea82649d54f',
      '649b91151a74c2dccaafc26f',
      '649b91221a74c2dccaafc293',
      '649b91371a74c2dccaafc2e5',
      '649b91491a74c2dccaafc322',
      '649b9750351e543a2370d631',
      '649b9760351e543a2370d658',
      '649b976f351e543a2370d67e',
      '649b9782351e543a2370d6c2',
      '649b978d351e543a2370d6e7',
      '649b979a351e543a2370d6fb',
      '649b97a8351e543a2370d723',
      '649b97b1351e543a2370d73e',
      '649b97bd351e543a2370d767',
      '649b97ca351e543a2370d791',
      '649b97db351e543a2370d7ce',
      '649b97e4351e543a2370d7df',
      '649b97ef351e543a2370d7fd',
      '649b97f9351e543a2370d81b',
      '649b9802351e543a2370d83a',
      '649b980d351e543a2370d864',
      '649b981c351e543a2370d8a0',
      '649b982c351e543a2370d8e0',
      '649b9839351e543a2370d904',
      '649b9845351e543a2370d926',
      '649b9852351e543a2370d955',
      '649b985d351e543a2370d977',
      '649b986c351e543a2370d9b4',
      '649b9879351e543a2370d9e7',
      '649b9884351e543a2370da0a',
      '649b988e351e543a2370da22',
      '649b989a351e543a2370da43',
      '649b98a4351e543a2370da52',
      '649b98af351e543a2370da72',
      '649b98bd351e543a2370da99',
      '64b85cee5fb628843df4beb3',
      '65653c762b6edb063a9cdacd',
      '65653cbe5442baa655ac557d',
      '65653ce05aea11d09a66a266',
      '65653d07ad05b7cd6fc8c88d',
      '65653d297905c64f8cd8ca2b',
      '65653d6ea5762928dc58f908',
      '65653d91a5762928dc58f909',
      '65653dbd1c823a86965c92f8',
      '656f696965baca597ea44e10',
      '656f697265baca597ea44e44',
      '656f697965baca597ea44e80',
      '656f698465baca597ea44eda',
      '656f698b65baca597ea44f14',
      '656f699265baca597ea44f4c',
      '656f699865baca597ea44f84',
      '656f699f65baca597ea44fb4',
    ],
  },
  secondary: {
    name: 'Middle/High School Module Collection',
    description: 'These are modules for middle and high school kids.',
    courseIds: [
      '54cdf2923e00b1d50e977cc4',
      '54cdf2b73e00b1d50e977cc5',
      '54cdf2db3e00b1d50e977cc6',
      '54cdf2e93e00b1d50e977cc7',
      '54cdf3003e00b1d50e977cc8',
      '54e19e7fc24990e446ae09bc',
      '54e79ea189a8cbdc375186ac',
      '54ed163551fd1ddc50eed032',
      '54f34be94eeee85078948ff1',
      '54f3c125151f77fd7a09f00d',
      '54f8ee25ccfea5a61b9f0aea',
      '54fcbcbe03572d8b0368a169',
      '54ff970803572d8b0368a3a4',
      '54ffc41403572d8b0368a3eb',
      '54ffc45003572d8b0368a3ec',
      '54ffc46b03572d8b0368a3ed',
      '54ffc48303572d8b0368a3ee',
      '5500e9d303572d8b0368a52d',
      '55121515d5294693014eb3f4',
      '55124ff8d5294693014eb458',
      '55125042d5294693014eb459',
      '55341024d5294693014ec39a',
      '5681a87a37d4298f53b8d929',
      '57ae1c8fea861b3c20d0546e',
      '584b15f92fd5952c5216d757',
      '586ec4d627b32c73279f0598',
      '591657a3912245ef25c63cde',
      '5919e8c7681a642a1e38c328',
      '5b2e730e9915850019fbed01',
      '5b48de52721f89001925a3e2',
      '5b73abc34ee83b6bd87a8c93',
      '5b73abc34ee83b6bd87a8d5c',
      '5b73abc34ee83b6bd87a8da4',
      '5b73abc34ee83b6bd87a8e53',
      '5b73abc44ee83b6bd87a8fc0',
      '5b85c77624ed5ac21cab7022',
      '5bd0d16471152b1e9b62134b',
      '5bfda6bf79f8000019fa4e71',
      '5c895ff96ae16c994b496cfb',
      '5c895ff96ae16c994b496d65',
      '5c895ff96ae16c994b496e1c',
      '5c895ff96ae16c994b496ec5',
      '5c895ff96ae16c994b496f39',
      '5c895ff96ae16c994b496fab',
      '5c895ff96ae16c994b49701c',
      '5c895ff96ae16c994b4970b5',
      '5c8a920bf5af086a1a0c60fc',
      '5c9e63e4f865a9246ed17f17',
      '5c9e6480ea16af729dd36bf6',
      '5c9e64b36646891325ef840b',
      '5c9e64b36646891325ef846d',
      '5c9e64b36646891325ef8509',
      '5c9e64b36646891325ef8572',
      '5c9e64b36646891325ef860e',
      '5c9e66c3c53d9680283899b8',
      '5c9e66c3c53d968028389a87',
      '5c9e66c3c53d968028389ba0',
      '5c9e68025b564d22a2a47699',
      '5c9e68025b564d22a2a4776d',
      '5ca91264111d969b3f21982a',
      '5ca9135922254e5698869903',
      '5cb9decefcda63cb384c2a8e',
      '5e8924ca18b2d907997e85b7',
      '5e94b62d459fda0016baa043',
      '5f3708a22816150017da701d',
      '57d5078a5817d85b78e8c12a',
      '57d5078a5817d85b78e8c140',
      '57d5078c5817d85b78e8c1de',
      '57d5078c5817d85b78e8c230',
      '57d5078e5817d85b78e8c25a',
      '57d5078e5817d85b78e8c27f',
      '57d5078e5817d85b78e8c285',
      '57d5078e5817d85b78e8c2a1',
      '57d5078e5817d85b78e8c2ab',
      '57d5078e5817d85b78e8c2b2',
      '57d5078e5817d85b78e8c2cd',
      '57d5078e5817d85b78e8c34f',
      '57d5078e5817d85b78e8c36e',
      '57d5078e5817d85b78e8c38c',
      '57d5078e5817d85b78e8c3b5',
      '57d5078e5817d85b78e8c3ef',
      '57d5078e5817d85b78e8c423',
      '57d5078e5817d85b78e8c43d',
      '57d5078e5817d85b78e8c468',
      '57d5078e5817d85b78e8c48a',
      '57d5078e5817d85b78e8c499',
      '57d5078e5817d85b78e8c4a7',
      '57d5078e5817d85b78e8c4d5',
      '57d5078e5817d85b78e8c553',
      '57d5078e5817d85b78e8c574',
      '57d5078f5817d85b78e8c595',
      '57d5078f5817d85b78e8c627',
      '57d5078f5817d85b78e8c657',
      '57d5078f5817d85b78e8c66b',
      '57d5078f5817d85b78e8c687',
      '57d5078f5817d85b78e8c6f2',
      '57d5078f5817d85b78e8c70e',
      '57d5078f5817d85b78e8c737',
      '57d5078f5817d85b78e8c7a8',
      '57d5078f5817d85b78e8c7f8',
      '57d5078f5817d85b78e8c813',
      '57d5078f5817d85b78e8c836',
      '57d5078f5817d85b78e8c875',
      '5fa8d34a4a6767001705814b',
      '5f9af5e988ac68541b816639',
      '5ed6ac398673c03267677b96',
      '60995c6f844d0f00197342f7',
      '61b939db2fa493001afd83a8',
    ],
  },
  parent: {
    name: 'Parent/Guardian Module Collection',
    description: 'Parent/Guardian Module Collection',
    courseIds: [
      '58465cb969534cda03d0fea5',
      '584ecb763bb8c5fff7722ff1',
      '584ecbf7af20c5047a39f7fb',
      '584ecc3a4810d006da9ce9f9',
      '58465d56b3565746d5ddab5a',
      '59a7fe7b9169c5b461a8111d',
      '59a809d606225bf063d6246b',
      '59a80a3fe481f9f74b976adb',
      '59a80b4066ab68a115cb5ac2',
      '59a80b92bd68983d31457250',
      '59a80e7ea2dff7f69fb3361a',
      '59dbfe14926613fec6330b3d',
      '59dbfee6862eec98488889fd',
      '59dc01497b5603a40fd189fe',
      '59dc0194fd0418a62fc91814',
      '59dc01cd5ab26f7b587610eb',
      '59dc02f9ff40e0922c4307b6',
      '59dc0363923cd248f097d647',
      '59ed851279534827fefdeb4f',
      '59ed85789d4d78e08a6f0b76',
      '59ed85b6381c0045469c3769',
      '59ed85f9193a301e01707282',
      '59ed86434fdb16d06870bbc1',
      '59ed8674dce0405cff501038',
      '59ed89b06188cf20e1223a1c',
      '59ed89df2af2e187f783b95e',
      '5ee6841043a339b188e83c48',
      '5f698f3c7a81f8abfa94460c',
      '5f9af521898b4dceb5844670',
      '600f07f06803927c7e2b5842',
      '600f0a8eec0dad012ca02157',
      '602b796f6e3b5f362f787f1f',
      '6031e3cab5ed8716dae83431',
      '6033f16aff1f5dafd5cfd82b',
      '6033f16bff1f5dafd5cfd84f',
      '6033f16bff1f5dafd5cfd867',
      '6033f16bff1f5dafd5cfd893',
      '6033f16bff1f5dafd5cfd8b4',
      '6033f16bff1f5dafd5cfd903',
      '6033f16bff1f5dafd5cfd92f',
      '6033f16bff1f5dafd5cfd95f',
      '60397cb8afc9a0c5fc07d502',
      '615cda69e0e4b1d5c45286c0',
      '62e78b877b694dc4ba5e42da',
      '62f08961e64053a747e83aee',
      '62f0899fe64053a747e83ba7',
      '656530e2a0c3a83aa0fc92ba',
      '656530efa0c3a83aa0fc92e6',
      '656530fca0c3a83aa0fc9322',
      '65653129a0c3a83aa0fc93ad',
      '65653143a0c3a83aa0fc9405',
      '65653154a0c3a83aa0fc9461',
      '65653164a0c3a83aa0fc949f',
      '65653174a0c3a83aa0fc94f1',
    ],
  },
  professional: {
    name: 'Professional Development Module Collection',
    description: 'These are Professional Development modules.',
    courseIds: [
      '5cd1c19a37351b001607ad57',
      // '5d589e35cbc2d000156cb6ab',
      '60744c06da51eb0017e46d42',
      '60744c50da51eb0017e46d6b',
      '6081a0e11ae80900173e287c',
      '6083274d85c43a0017168511',
    ],
  },
  middleEast: {
    name: 'Middle East Module Collection',
    description: 'These are Middle East modules.',
    courseIds: [
      '60240fe0563628b6a0e118a4',
      '5ffd100d69bb375135b3bfb0',
      '5ffd10ae18c08b01929482af',
      '5ffd13f950bac020cc6f216c',
      '5ffdee065b5d64b1e4955c2f',
      '5ffd163094071ea448188edd',
      '5ffd162f94071ea448188db5',
      '5ffd1150a29ea16727f9de78',
      '600268b23687b5d0a1e74129',
      '5ffd1288125aad86b1168d3a',
      '5ffd13800d6059a6cfe0a6a3',
    ],
  },
  wellness: {
    name: 'Wellness Series Video Collection',
    description: 'These are wellness series videos.',
    courseIds: [
      'c_af65009a_08a1_4621_8c58_2f43bd192f0d',
      'c_b53db3c8_af99_4643_889f_e796c65d6a8c',
      'c_49efcf56_156c_454c_b0a1_7d28bb099efc',
      'c_8042af9a_0a52_4c4b_bc9b_ca811c6c7c6c',
      'c_6e65dba9_76f3_4fe1_9192_a619ea74d8b4',
      'c_6a08c9bf_3743_4f03_b296_c88e6e0b1658',
      'c_c73da906_5061_4d37_9d66_83a81497207d',
    ],
  },
  unreleased: {
    name: 'Unreleased',
    courseIds: [], // leave empty
  },
  other: {
    name: 'Other',
    courseIds: [
      '6264967d7203a901ed00d685',
      '6175dacf2eb0d70013993ad3',
      '617779ff6dcafa0013bd0a0c',
      '62aa45aa384dd7f9256d9de0',
      '626a21bc11a914ce6b72138b',
      '6542ad48520f0c1a55ea05e5',
    ],
  },
}
const welcomeCourseIds = [
  '5771f05d14aae69912bad0d7',
  '584ecbb7261e9fe97149376a',
]

const CoursePack = ({
  onChange,
  isSaving,
  packName,
  packCourseIds,
  selectedCourseIds,
  availableCourses,
  isDisabled,
}) => {
  const selectedPackCourseIds = intersection(selectedCourseIds, packCourseIds)
  const defaultPackState = (!selectedPackCourseIds.length) ? 'off' : (selectedPackCourseIds.length === packCourseIds.length) ? 'on' : 'custom'
  const [ packState, setPackState ] = useState(defaultPackState)
  const [ showCourseList, setShowCourseList ] = useState(false)

  if (!availableCourses.some((course) => packCourseIds.includes(course.id))) {
    return null
  }
  const sortedCourses = sortBy(availableCourses, (course) => course.titles || course.title.en)
  const instructionalCourseIds = packCourseIds.filter((id) => {
    const match = find(availableCourses, { id })
    return (match && match.isInstructional)
  })
  const interactiveCourseIds = difference(packCourseIds, instructionalCourseIds)
  return (
    <Segment
      id={`course-pack-${packName.toLowerCase().replace(/ /g, '-')}`}
      disabled={isSaving}
      key={`course-pack-${packName}`}
      color={(packState === 'custom') ? 'blue' : (packState === 'on') ? 'green' : 'red'}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Header as='h4' style={{ flexGrow: 2, margin: 0 }}>{packName}</Header>
        <Button.Group floated='right' size='mini' basic style={{ flexShrink: 2 }}>
          <Button
            className='pack-off'
            type='button'
            active={packState === 'off'}
            disabled={isSaving || isDisabled}
            onClick={() => {
              setPackState('off')
              onChange(difference(selectedPackCourseIds, packCourseIds))
            }}
          >
          Off
          </Button>
          <Button
            className='pack-custom text white'
            color='blue'
            type='button'
            active={packState === 'custom'}
            disabled={isSaving || isDisabled}
            onClick={() => {
              setPackState('custom')
            }}
          >
          Customized
          </Button>
          <Button
            className='pack-on'
            type='button'
            active={packState === 'on'}
            disabled={isSaving || isDisabled}
            onClick={() => {
              setPackState('on')
              onChange(union(selectedPackCourseIds, packCourseIds))
            }}
          >
          On
          </Button>
        </Button.Group>
      </div>
      {(packState === 'custom' || isDisabled) && (
        <>
          <Accordion styled fluid style={{ marginTop: 10 }}>
            <Accordion.Title
              active={showCourseList}
              index={0}
              onClick={() => setShowCourseList(!showCourseList)}
              className='text bold grey'
            >
              <Icon name='dropdown' /> Module List
            </Accordion.Title>
            <Accordion.Content active={showCourseList} style={{ padding: 10 }}>
              {(!isDisabled) && (
                <div style={{ marginBottom: 10 }}>
                  <Button
                    type='button'
                    size='mini'
                    disabled={isSaving}
                    icon
                    onClick={() => onChange(union(selectedPackCourseIds, packCourseIds))}
                  >
                    <Icon name='plus square outline' /> Select All
                  </Button>
                  <Button
                    type='button'
                    size='mini'
                    disabled={isSaving}
                    icon
                    onClick={() => onChange(difference(selectedPackCourseIds, packCourseIds))}
                  >
                    <Icon name='minus square outline' /> Select None
                  </Button>
                  {(!!instructionalCourseIds.length) && (
                    <>
                      <Button
                        type='button'
                        size='mini'
                        disabled={isSaving}
                        icon
                        onClick={() => onChange(interactiveCourseIds)}
                      >
                        <Icon name='plus square outline' /> Select Only Interactive Versions
                      </Button>
                      <Button
                        type='button'
                        size='mini'
                        disabled={isSaving}
                        icon
                        onClick={() => onChange(instructionalCourseIds)}
                      >
                        <Icon name='plus square outline' /> Select Only Instructional Versions
                      </Button>
                    </>
                  )}
                </div>
              )}
              {(isDisabled) && (
                <List relaxed style={{ marginLeft: 10 }}>
                  {sortedCourses.map((course) => {
                    const hasCourse = packCourseIds.some((id) => course.id === id)
                    if (!hasCourse) {
                      return null
                    }
                    const title = course.titles || course.title.en
                    return (
                      <List.Item
                        style={{ display: 'flex', alignItems: 'center' }}
                        key={`course-option-${course.id}`}
                        icon={{ name: 'fas fa-chalkboard', className: (selectedPackCourseIds.includes(course.id)) ? 'base-green' : 'base-red' }}
                        content={(course.isInstructional) ? title + ' (Instructional Version)' : title}
                      />
                    )
                  })}
                </List>
              )}
              {(!isDisabled) && (
                <>
                  {sortedCourses.map((course) => {
                    const hasCourse = packCourseIds.some((id) => course.id === id)
                    if (!hasCourse) {
                      return null
                    }
                    const title = course.titles || course.title.en
                    return (
                      <Checkbox
                        key={`course-option-${course.id}`}
                        style={{ marginBottom: 5, display: 'block' }}
                        label={(course.isInstructional) ? title + ' (Instructional Version)' : title}
                        toggle
                        disabled={isSaving}
                        checked={selectedPackCourseIds.includes(course.id)}
                        onChange={(e, p) => {
                          if (p.checked) {
                            onChange(union(selectedPackCourseIds, [ course.id ]))
                          } else {
                            onChange(difference(selectedPackCourseIds, [ course.id ]))
                          }
                        }}
                      />
                    )
                  })}
                </>
              )}
            </Accordion.Content>
          </Accordion>
        </>
      )}
    </Segment>
  )
}

CoursePack.propTypes = {
  availableCourses: PropTypes.array,
  selectedCourseIds: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  packName: PropTypes.string.isRequired,
  packCourseIds: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
}

CoursePack.defaultProps = {
  availableCourses: [],
  selectedCourseIds: [],
  isSaving: false,
  packCourseIds: [],
  isDisabled: false,
}

const CourseSelector = ({
  availableCourses,
  selectedCourseIds,
  onChange,
  isSaving,
  isDisabled,
  hideHeader,
  hideParentCourses,
  showOnlyParentCourses,
}) => {
  return (
    <Segment className='course-selection' basic style={{ padding: 0 }} disabled={isSaving}>
      {(!hideHeader) && (
        <Header as='h4' style={{ display: 'flex', alignItems: 'center' }}>
          <Icon className='fas fa-chalkboard' />
          <Header.Content data-public>
          Modules
            <Header.Subheader data-public>Select modules for this organization</Header.Subheader>
          </Header.Content>
        </Header>
      )}
      {isDisabled && (
        <Message icon warning visible={true}>
          <Icon name='warning' />
          <Message.Content data-public>
            Only admins have the ability to modify the module selections. Please contact your organizational admin to change these settings.
          </Message.Content>
        </Message>
      )}
      {Object.keys(coursePacks).map((packKey) => {
        let packCourseIds = coursePacks[packKey].courseIds.slice(0)
        // other courses pack logic
        if (!packCourseIds.length) {
          const allKnownPackCourseIds = flatMap(Object.values(coursePacks), 'courseIds')
          packCourseIds = map(availableCourses.filter((course) => !allKnownPackCourseIds.concat(welcomeCourseIds).includes(course.id)), 'id')
        }
        return (
          <CoursePack
            key={packKey}
            packName={coursePacks[packKey].name}
            packCourseIds={packCourseIds}
            availableCourses={(showOnlyParentCourses) ? availableCourses.filter((c) => c.type === 'parent' || (c.type === 'elementary' && !!c.videoUrl)) : (!hideParentCourses) ? availableCourses : availableCourses.filter((c) => c.type !== 'parent')}
            selectedCourseIds={selectedCourseIds}
            onChange={(selectedPackCourseIds) => onChange(union(difference(selectedCourseIds, packCourseIds), selectedPackCourseIds, welcomeCourseIds))}
            isSaving={isSaving}
            isDisabled={isDisabled}
          />
        )
      })}
    </Segment>
  )
}

CourseSelector.propTypes = {
  availableCourses: PropTypes.array,
  selectedCourseIds: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hideHeader: PropTypes.bool,
  hideParentCourses: PropTypes.bool,
  showOnlyParentCourses: PropTypes.bool,
}

CourseSelector.defaultProps = {
  availableCourses: [],
  selectedCourseIds: [],
  isSaving: false,
  isDisabled: false,
  hideHeader: false,
  hideParentCourses: false,
  showOnlyParentCourses: false,
}

CourseSelector.coursePacks = coursePacks

export default CourseSelector
